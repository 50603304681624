import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import * as locationsStyles from "./locations.module.scss"
import { Map } from "../components/locations/map"
import Seo from "../components/seo"

const Locations = () => {
  const eastDefaultProps = {
    center: { lat: 39.94915888578044, lng: -75.16195130792016 },
    zoom: 18,
  }

  const eastState = {
    center: { lat: 39.94915888578044, lng: -75.16195130792016 },
  }

  const westDefaultProps = {
    center: { lat: 39.953052290883086, lng: -75.19310193753037 },
    zoom: 18,
  }

  const westState = {
    center: { lat: 39.953052290883086, lng: -75.19310193753037 },
  }

  return (
    <div>
      <Seo
        title="Locations"
        description="Here you will find both an illustrated map and and interactive map showing the locations of both Modern Eye Stores"
      />
      <div aria-hidden="true" className={locationsStyles.redLine}></div>
      <StaticImage
        placeholder="blurred"
        src="../images/locations/mapillustration.png"
        alt="Illustration of a hand pointing at a map of Philadelphia, with a folded up map next to it. Below is a horizontal map of University City and Center City Philadelphia, marking the modern eye locations and environs."
      />
      <h1 className={locationsStyles.visuallyHiddenHeader}>Locations</h1>
      <div className={locationsStyles.mapsLayout} role="group">
        <figure aria-label="Modern Eye University City on Google Maps">
          <Map defaultProps={westDefaultProps} state={westState} />
          <h2 className={locationsStyles.westMapHeader}>
            Modern Eye <br /> University City
          </h2>
        </figure>
        <figure aria-label="Modern Eye Center City on Google Maps">
          <Map defaultProps={eastDefaultProps} state={eastState} />
          <h2 className={locationsStyles.eastMapHeader}>
            Modern Eye <br /> Center City
          </h2>
        </figure>
      </div>
    </div>
  )
}

export default Locations
