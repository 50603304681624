import React, { Component } from "react"
import GoogleMapReact from "google-map-react"
import { FaMapMarkerAlt } from "react-icons/fa"
import * as mapStyles from "./map.module.scss"

export class Map extends Component {
  render() {
    return (
      <div className={mapStyles.map}>
        <GoogleMapReact
          bootstrapURLKeys={{
            key: process.env.GATSBY_GOOGLE_MAPS_API,
          }}
          defaultCenter={this.props.defaultProps.center}
          center={this.props.state.center}
          defaultZoom={this.props.defaultProps.zoom}
        >
          <FaMapMarkerAlt
            className={mapStyles.marker}
            lat={this.props.defaultProps.center.lat}
            lng={this.props.defaultProps.center.lng}
          />
        </GoogleMapReact>
      </div>
    )
  }
}

export default Map
